<template>
    <section class="page-wrap">
      <!-- 输入框 start -->
      <div class="input-wrap">
        <el-input
          v-model="sheetOrder"
          placeholder="请在此输入或扫描供货单编码"
          ref="refSheetOrder"
          autofocus="true"
          @keyup.enter.native="onHandleSearch"
        >
          <el-button
            slot="suffix"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onHandleSearch"
            >确定</el-button
          >
        </el-input>
      </div>
      <!-- 输入框 end -->
  
      <!-- 供货单布局 start -->
      <div v-if="isShowInfo" class="table-wrap" v-loading="loading">
        <div class="sheet-info">
          <div class="sheet-info__header">
            供货单号：{{ formData.supplierOrder }}
          </div>
          <div class="sheet-info__body">
            <div class="print__count" v-if="formData.print_ed_num">
              {{ `打印次数：${formData.print_ed_num}次` }}
            </div>
            <div class="info-one">供货日期：{{ formData.supplierDate }}</div>
            <div class="info-two">
              <div>供货商名称：{{ formData.supplierTitle }}</div>
              <div>联系人：{{ formData.supplierName }}</div>
              <div>联系电话：{{ formData.supplierMobile }}</div>
            </div>
            <div class="info-three">
              <div>SKU数量：{{ formData.sku }}</div>
              <div>总件数：{{ formData.productTotal }}件</div>
            </div>
            <!-- <div class="print">
              <el-button type="warning" @click="onHandlePrint(1)"  >打印装车单</el-button>
              <el-button type="warning" @click="onHandlePrint(2)" :loading="dialogloding" >直接打印</el-button>
            </div> -->
          </div>
        </div>
        <div class="table-content">
          <table-list :tableData="tableData"></table-list>
        </div>
      </div>
      <!-- 供货单布局 end -->
  
      <!-- 缺省 start -->
      <c-empty-page v-else v-loading="loading"
      element-loading-text="加载中。。。。" tip="请扫描或输入供货单编号~"></c-empty-page>
      <!-- 缺省 end -->
  
      <!-- 打印原因弹窗 start -->
      <print-msg-dialog
        ref="refPrintMsgDialog"
        @on-handle-print="ajaxGetGoodsSheetPrint"
        @onClose="onClose"
      ></print-msg-dialog>
      <!-- 打印原因弹窗 end -->
    </section>
  </template>
  
  <script>
  import TableList from "./modules/table-list";
  import CEmptyPage from "@/components/common/CEmptyPage";
  import PrintMsgDialog from "./modules/print-msg-dialog/index.vue";
  import {
    getGoodsSheetInfo,
    // getGoodsSheetList,
    getGoodsSheetPrint,
    entruckprintEdoss,
    entruckreport,
    postPrintLogOfOrder
  } from "@/api/sheet-to-review/index";
  import { formatDate, expirationValid } from "@/utils/tools/date.js";
  // 局部引入
  import { hiprint } from "vue-plugin-hiprint";
  
  import { BASE } from "@/api/index";
import { number } from "echarts";
  
  const BASE_URL = BASE.PRO2;
  export default {
    name: "SheetPrint",
    data() {
      return {
        loading: false,
        // 供货单编号
        sheetOrder: "",
        sheetOrdercopy: "",
        // 供货单详情
        formData: {
          supplierOrder: "",
          supplierDate: "",
          supplierTitle: "",
          supplierName: "",
          supplierMobile: "",
          sku: "",
          productTotal: "",
          print_ed_num: "", // 打印次数
        },
        num:0,
        tableData: [],
        // 是否展示打印详情
        isShowInfo: false,
        Printtype:'',//1打印装车单  2直接打印
        dialogloding: false, // 直接打印按钮防止连点
      };
    },
    components: {
      TableList,
      CEmptyPage,
      PrintMsgDialog,
    },
    mounted() {
      // this.onHandleSearch();
      this.$refs.refSheetOrder.focus();
    },
    methods: {
      //   print(row) {
      //     let contractUrl = "https://test-logistics.manage-purchase.hbxinfadi.com/index.php/api/order/entruck/printEd?supplierOrderNo=SOG20240910164557282SJDE4QZR&token=902K1O3T4S20762NE5AR4M4B41WFBF2X6Q5"
  
      //           //使用默认打印机
      //           hiprint.hiwebSocket.send({ client:'TEST_PRINT', type: 'url_pdf', pdf_path: 'https://test-logistics.manage-purchase.hbxinfadi.com/index.php/api/order/entruck/printEd?supplierOrderNo=SOG20240910164557282SJDE4QZR&token=902K1O3T4S20762NE5AR4M4B41WFBF2X6Q5' })
      //     // fetch(contractUrl).then(res => {
      //     //   return res.blob() //将url地址转化为blob
      //     // }).then(res => {
      //     //   var iframe = document.createElement('iframe')
      //     //   iframe.style.frameborder = 'no'
      //     //   iframe.style.display = 'none'
      //     //   iframe.style.pageBreakBefore = 'always'
      //     //   iframe.setAttribute('id', `printPdf${row.pk}`) //id要是唯一的，不然会获取到上一个PDF文件的内容
      //     //   iframe.setAttribute('name', `printPdf${row.pk}`)
      //     //   iframe.src = window.URL.createObjectURL(res) //创建一个包含指定对象的URL
      //     //   document.body.appendChild(iframe)
      //     //   this.doPrint(`printPdf${row.pk}`)
      //     //   window.URL.revokeObjectURL(iframe.src) //释放url
      //     // })
      //   },
      //   doPrint(val) {
      //     var ordonnance = document.getElementById(val).contentWindow
      //     setTimeout(() => {
      //       ordonnance.print()
      //       ordonnance.close();
      //     }, 500)
      //   },
      /**
       * 查询供货单相关
       */
      async onHandleSearch() {
        if (this.loading) {
        this.$message.error("正在打印中请打印完成上次在扫描~");
        this.sheetOrder = "";
        this.$refs.refSheetOrder.focus();
        return;
      }
        if (!this.sheetOrder) {
          this.$message.error("请扫描或输入供货单编号~");
          return;
        }
        console.log(this.sheetOrdercopy,this.sheetOrder,'111111111111111111')
        if(this.sheetOrdercopy==this.sheetOrder){
          this.$message.error("该装车单已打印过，请不要重复打印~");
          return;
        }
        this.sheetOrdercopy=this.sheetOrder;
        this.loading = true;
        const params = {
          supplierOrderNo: this.sheetOrder,
        };
        try {
          const { data } = await getGoodsSheetInfo(params);
          // const { data: list } = getGoodsSheetList();
          const {
            supplier_order_no: supplierOrder,
            date: supplierDate,
            supplier_title: supplierTitle,
            supplier_fullname: supplierName,
            supplier_mobile: supplierMobile,
            sku,
            product_total_number: productTotal,
            print_ed_num,
            list,
            print_pdf_right
          } = data;

            this.num=print_ed_num;
          
          this.sheetOrder = "";
          this.formData = {
            supplierOrder,
            supplierDate,
            supplierTitle,
            supplierName,
            supplierMobile,
            print_ed_num,
            sku,
            productTotal,
            print_pdf_right
          };
          if(this.num==0){
          postPrintLogOfOrder({supplier_order_no:this.formData.supplierOrder});
        }
          this.tableData = list;
          this.isShowInfo = true;
          this.onHandlePrint(2)
          console.log("ajax getGoodsSheetInfo", this.tableData, this.formData);
        } catch (err) {
          this.sheetOrdercopy="";
          this.sheetOrder="";
          console.log("ajax getGoodsSheetInfo err", err);
        } finally {
          this.loading = false;
        }
      },
      onClose(){
        this.dialogloding=false;
      },
      /**
       * 打印装车单
       */
      onHandlePrint(e) {
        if (!this.formData.supplierOrder) {
          this.$message.error("请扫描或输入供货单编号~");
          return;
        }
       
        this.Printtype=e;
        if(e!=1){
          this.dialogloding=true;
        }
        /**
         * 1. 判断是不是当前的供货单
         * 2. 非第一次打印
         */
        const { supplierDate, print_ed_num } = this.formData;
        const expiration = expirationValid(supplierDate, formatDate());
        console.log("判断能不能打印", supplierDate,expiration);
        // 当未过期，且第一次打印，则无需提示信息
        if (expiration && !print_ed_num) {
          this.ajaxGetGoodsSheetPrint();
          return;
        }else  {
        this.$message.error("已经打印过 不可再次打印~");
        this.dialogloding = false;
      }
        // this.$refs.refPrintMsgDialog.onInitData(this.formData);
      },
      /**
       * 打印接口请求
       */
      ajaxGetGoodsSheetPrint() {
        // this.$message.success("正在打印，请稍等~");
        try {
          const params = {
            supplierOrderNo: this.formData.supplierOrder,
          };
          if(this.Printtype==1){
            //走预览
            return getGoodsSheetPrint(params, this.onInit);
          }else{
            // console.log(Number(this.num)>0,2222222222222222222222222)
            if(Number(this.num)>0){
              // console.log("打印次数",this.num,1111111111111111111111111111)
              this.$message.error("该装车单已打印过，请不要重复打印~");
              return;
            }
            //直接打印
            let url = `${BASE_URL}/order/entruck/printEd`;
          let token = sessionStorage.getItem("token");
          if (!token) {
            this.$message.error("账号登录异常");
            return;
          }
          let query = `supplierOrderNo=${params.supplierOrderNo}&token=${token}`;
           // 判断是否已成功连接
           if (hiprint.hiwebSocket.opened) {
            this.loading = true;
            this.num = Number(this.num) + 1;
            console.log("打印次数", this.num);
            // 注意：连接是异步的
            // 已连接
            entruckprintEdoss({ supplierOrderNo: params.supplierOrderNo })
              .then((res) => {
                console.log(res, "打印成功", `${url}?${query}`);
                //使用默认打印机
                hiprint.hiwebSocket.send({
                  client: "TEST_PRINT",
                  type: "url_pdf",
                  pdf_path: res.data.url,
                });
                this.$message.success("打印成功~");
                entruckreport({
                  supplierOrderNo: params.supplierOrderNo,
                  url: res.data.url,
                }).then((res) => {
                  console.log(res, "上报成功");
                });
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
              });
          } else {
            // console.log("1111111,没连接");
            this.$message.error("请连接打印机");
            this.loading = false;
          }
          }
          
          this.dialogloding = false;
          // 
        } catch (err) {
          // this.$message.error("打印失败，请稍后再试~");
          this.dialogloding = false;
          console.log("ajax getGoodsSheetPrint err", err);
        }
      },
      /**
       * 初始哈页面
       */
      onInit() {
        this.isShowInfo = false;
        this.$refs.refSheetOrder.focus();
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .page-wrap {
    height: 100%;
    padding: 6px;
    box-sizing: border-box;
    .input-wrap {
      padding-bottom: 6px;
      /deep/ .el-input {
        position: relative;
        &__inner {
          font-size: 16px;
          height: 45px;
          line-height: 45px;
          &::placeholder {
            // font-size: 18px;
            padding-left: 10px;
          }
        }
        &__suffix {
          top: 5px;
          right: 20px;
        }
      }
      /deep/ .el-button {
        padding: 0 15px;
        height: 34px;
        .el-icon-search {
          font-size: 12px;
        }
        span {
          font-size: 14px;
        }
      }
    }
    .table-wrap {
      height: calc(100% - 60px);
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      .table-content {
        // max-height: 790px;
        height: calc(100% - 240px);
        overflow: auto;
      }
    }
    .sheet-info {
      &__header {
        padding-bottom: 20px;
        text-align: center;
        font-size: 22px;
        color: #333333;
      }
      &__body {
        font-size: 20px;
        .info-one {
          line-height: 35px;
          font-weight: 600;
        }
        .info-two,
        .info-three {
          display: flex;
          gap: 60px;
          line-height: 40px;
        }
        .print {
          padding-top: 20px;
          padding-bottom: 25px;
          .btn {
            padding: 8px 12px;
            cursor: pointer;
            border-radius: 4px;
            display: inline-block;
            font-size: 15px;
            color: #fff;
            background-color: #fa884c;
          }
        }
        .print__count {
          margin-bottom: 20px;
          font-size: 32px;
          font-weight: 600;
          color: $danger-color;
        }
      }
    }
  }
  </style>
  